import { groq } from 'next-sanity';
import Layout from '../components/Layout';
import EditorialPage from '../components/pages/EditorialPage';
import ServicePage from '../components/pages/ServicePage';
import StandardArticlePage from '../components/pages/StandardArticlePage';
import TopicPage from '../components/pages/TopicPage';
import UnderConstructionPage from '../components/pages/UnderConstructionPage';
import { ParentReferenceArray } from '../constants/parentArray';
import ParentPathQuery from '../groq/menus/ParentPathQuery';
import editorialPageQuery from '../groq/pages/editorialPage';
import { servicePageQuery } from '../groq/serviceQuery';
import { topicPageQuery } from '../groq/topicQuery';
import { sanityClient } from '../lib/sanity.server';
import {
  ArticleStandardPageType,
  EditorialPageType,
  MenuType,
  ServicePageType,
  SettingsType,
  TopicPageType,
} from '../types';
import vehicleValuationQuery from '../groq/vehicleValuation';
import VehicleValuationPage, {
  VehicleValuationPageType,
} from '../components/pages/VehicleValuationPage';
import articleStandardPageQuery from '../groq/articleStandardPageQuery';

interface PageProps {
  data: {
    menus: MenuType;
    settings: SettingsType;
    content:
      | TopicPageType
      | EditorialPageType
      | ArticleStandardPageType
      | ServicePageType
      | VehicleValuationPageType;
  };
}

const renderPageByType = (content: { _type: string }) => {
  switch (content._type) {
    case 'articleStandard':
      return <StandardArticlePage article={content as ArticleStandardPageType} />;
    case 'service':
      return <ServicePage service={content as ServicePageType} />;
    case 'topic':
      return <TopicPage topic={content as TopicPageType} />;
    case 'editorialPage':
      return <EditorialPage content={content as EditorialPageType} />;
    case 'vehicleValuation':
      return <VehicleValuationPage content={content as VehicleValuationPageType} />;
    default:
      return <UnderConstructionPage />;
  }
};

function DynamicPage({ data }: PageProps) {
  const { menus, settings, content } = data;

  return (
    <Layout menus={menus} settings={settings} seo={content.seo} defaultSEO={content.defaultSEO}>
      {renderPageByType(content)}
    </Layout>
  );
}

export async function getStaticPaths() {
  const slugs = await sanityClient.fetch(
    groq`*[_type in $parentReferenceTypes && !(parent->slug.current in ['kjope-bil'])] { ${ParentPathQuery} }`,
    { parentReferenceTypes: ParentReferenceArray },
  );

  const paths = slugs.map(({ slug }: { slug: string }) => {
    return {
      params: {
        slug: [...slug.split('/')],
      },
    };
  });

  return {
    paths,
    fallback: 'blocking',
  };
}

export const getStaticProps = async ({ params }: { params: { slug: string[] } }) => {
  const { slug = [] } = params;

  // Fetch all paths that exists
  const existingPaths = await sanityClient.fetch(
    groq`*[_type in $parentReferenceTypes] { ${ParentPathQuery} }`,
    { parentReferenceTypes: ParentReferenceArray },
  );

  // If current slug doesn't exist in existing paths, return 404
  const currentSlugExistInPaths = existingPaths.some(
    (path: { slug: string }) => slug.join('/') === path.slug,
  );

  if (!currentSlugExistInPaths) return { notFound: true };

  // Fetch type of current slug to query correct content
  const { _id, _type } = await sanityClient.fetch(
    groq`*[slug.current == $slug && !(_id in path('drafts.**'))][0]{_id, _type}`,
    { slug: slug.at(-1) },
  );

  let query = '';
  switch (_type) {
    case 'editorialPage': {
      query = editorialPageQuery;
      break;
    }
    case 'topic': {
      query = topicPageQuery;
      break;
    }
    case 'articleStandard': {
      query = articleStandardPageQuery;
      break;
    }
    case 'service': {
      query = servicePageQuery;
      break;
    }
    case 'vehicleValuation': {
      query = vehicleValuationQuery;
      break;
    }
    default:
      break;
  }

  if (!query) return { notFound: true };

  const data = await sanityClient.fetch(query, { slug: slug.at(-1), currentDocumentId: _id });

  if (!data) return { notFound: true, revalidate: 60, props: {} };

  return {
    props: {
      data,
    },
    revalidate: 1,
  };
};

export default DynamicPage;
